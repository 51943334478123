import React from 'react'
import Img from 'gatsby-image'

import styles from './hero.module.css'

function Hero ({ data }) {
  const logoRef = React.useRef()
  const audioRef = React.useRef()
  React.useEffect( () => {
    const logoNode = logoRef.current
    const audioNode = audioRef.current

    let bubbles = logoNode.getElementsByClassName("bubble");
    for (var i = 0; i < bubbles.length; i++) {
      bubbles[i].style.animationDelay = `${Math.random()}s`;
      bubbles[i].style.animationDuration = `${Math.random() * 1 + 1}s`;
      bubbles[i].style.transform =
        `translate(${Math.random()}px, -${Math.random() * 4 + 7.5}px)`;
      bubbles[i].style.animationName = "pop";
      bubbles[i].style.animationIterationCount = "infinite";
      bubbles[i].style.animationTimingFunction = "ease-out";
      bubbles[i].style.fillMode = "forwards";
    }

    logoNode.addEventListener("mouseover", function() {
      for (var i = 0; i < bubbles.length; i++) {
        bubbles[i].style.animationDuration = `${(Math.random() * 1 + 1)/5 }s`;
      }
    })
    logoNode.addEventListener("mouseleave", function() {
      for (var i = 0; i < bubbles.length; i++) {
        bubbles[i].style.animationDuration = `${Math.random() * 1 + 1 }s`;
      }
    })
    newHover({
      audio: "bubbles-14830.mp3",
      hoverElementId: "logo--animated",
      title: "bubbles_popping"
    });
    function newHover(e) {
      var audio = new Audio();
      audio.src = e.audio;
      audio.className = e.title;
      let hoverElement = logoNode;
      hoverElement.className = e.title;
      hoverElement.addEventListener("mouseover", PlaySound);
      hoverElement.addEventListener("mouseleave", StopSound);
    }
    function PlaySound(e) {
      var thissound = audioNode;
      thissound.volume = 1;
      thissound.play();
    }
    function StopSound(e) {
      var thissound = audioNode;
      thissound.pause();
      thissound.currentTime = 0;
    }
  })

    return (
  <header className={styles.hero}>
    <div className={styles.hero__infoContainer}>
      <div className={styles.hero__info}>
        <audio ref={audioRef} className="bubbles_popping" src="heavy-bubbles-35889.mp3"></audio>
        <div id="logo-container" ref={logoRef}>
          <div id="logo--animated">
            <svg id="chemistry" viewBox="0 0 47 87" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M22 29H39V68.3771V69H38.9886C38.8198 73.5966 36.7878 77.9721 33.2886 81.2352C29.6316 84.6454 24.6717 86.5612 19.5 86.5612C14.3283 86.5612 9.36838 84.6454 5.71142 81.2352C2.41933 78.1653 0.425912 74.1107 0.0609056 69.8137C0.0206916 69.4842 0 69.1487 0 68.8084C0 68.749 0.000632091 68.6896 0.00189003 68.6305C0.00063082 68.5461 0 68.4616 0 68.3771H0.0111303C0.239478 63.9633 3.95275 60.4536 8.5 60.4536C13.0473 60.4536 16.7605 63.9633 16.9889 68.3771H16.9996C16.9996 68.9955 17.263 69.5886 17.7319 70.0259C18.2009 70.4631 18.8369 70.7088 19.5 70.7088C20.1631 70.7088 20.7991 70.4631 21.2681 70.0259C21.7264 69.5985 21.9884 69.0222 22 68.419V29Z" fill="#CBECE2" fill-opacity="0.5"/>
              <path d="M15.4959 70.3201C15.2052 69.7885 15.0877 69.4051 14.9703 68.5472L14.7434 70.8015L15.4959 70.3201Z" fill="#CBECE2" fill-opacity="0.5"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M22 42.4181C24.7651 44.0354 26.7991 43.3942 29.1732 42.6458C31.6629 41.8609 34.5267 40.9581 39 42.4181V68.0613V69H38.9749C38.7306 73.5647 36.7116 77.8952 33.2886 81.1427C29.6316 84.6122 24.6717 86.5613 19.5 86.5613C14.3283 86.5613 9.36838 84.6122 5.71142 81.1427C2.41933 78.0195 0.425916 73.8944 0.0609067 69.5229C0.020692 69.1875 0 68.8462 0 68.5C0 68.4395 0.0006321 68.3792 0.00189006 68.319C0.000630829 68.2331 0 68.1472 0 68.0613H0.0111304C0.23948 63.5708 3.95275 60 8.5 60C13.0473 60 16.7605 63.5708 16.9889 68.0613H16.9996C16.9996 68.6904 17.263 69.2938 17.7319 69.7386C18.2009 70.1835 18.8369 70.4334 19.5 70.4334C20.1631 70.4334 20.7991 70.1835 21.2681 69.7386C21.7264 69.3038 21.9884 68.7176 22 68.1038V42.4181Z" fill="url(#glass)"/>
              <path d="M15.4959 70.038C15.2052 69.4971 15.0877 69.1071 14.9703 68.2343L14.7434 70.5277L15.4959 70.038Z" fill="url(#liquid)"/>
              <rect x="14" y="19" width="33" height="10" rx="5" fill="#A8E8D5" fill-opacity="0.5"/>
              <circle className="bubble" cx="20.5" cy="9.5" r="2.5" fill="#306E36"/>
              <circle className="bubble" cx="27.5" cy="8.5" r="2.5" fill="#306E36"/>
              <circle className="bubble" cx="30.5" cy="17.5" r="2.5" fill="#306E36"/>
              <circle className="bubble" cx="28.5" cy="53.7806" r="2.5" fill="#306E36"/>
              <circle className="bubble" cx="43" cy="1" r="1" fill="#306E36"/>
              <circle className="bubble" cx="30" cy="46.0443" r="1" fill="#306E36"/>
              <circle className="bubble" cx="33" cy="44.0443" r="1" fill="#306E36"/>
              <circle className="bubble" cx="35" cy="41.6409" r="1" fill="#306E36"/>
              <circle className="bubble" cx="32" cy="43.6409" r="1" fill="#306E36"/>
              <circle className="bubble" cx="27" cy="45.0443" r="1" fill="#306E36"/>
              <defs>
              <linearGradient id="glass" x1="24.9953" y1="56.0763" x2="19.5" y2="86.5613" gradientUnits="userSpaceOnUse">
              <stop stop-color="#77B57D"/>
              <stop offset="0.848982" stop-color="#354B37"/>
              </linearGradient>
              <linearGradient id="liquid" x1="15.2257" y1="68.9713" x2="14.6013" y2="70.2805" gradientUnits="userSpaceOnUse">
              <stop stop-color="#9554FF"/>
              <stop offset="0.878411" stop-color="#840FCC"/>
              </linearGradient>
              </defs>
            </svg>

          </div>
        </div>
        <h1 className={styles.hero__headline}>
          {data.name}
        </h1>
        <p className={styles.hero__title}>
          {data.title}
        </p>
        <div
          className={styles.hero__shortBio }
          dangerouslySetInnerHTML={{
            __html: data.shortBio.childMarkdownRemark.html,
          }}
        />
        <div className="ctas-list">
          <a 
            href="https://www.figma.com/file/8X7yNUYF4sz0sgqFpvn90p/Board-of-Work-and-Lean-UX"
            target="_blank"
            className="cta primary"
          >
            Explore Recent Board of Work
          </a>
          <a 
            href="/Jonathan Fisher — Resume.pdf"
            target="_blank"
            className="cta secondary"
          >
            CV / Resume
          </a>
        </div>
      </div>
      <Img alt="profile image" className={styles.hero__image} fluid={data.heroImage.fluid} />
    </div>
  </header>
)
}

export default Hero;